import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { RequestWrapper } from "../HelperComponents";
import {
  Page,
  Text,
  Font,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  View,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans Light",
  src:
    "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf",
});

Font.register({
  family: "Open Sans",
  src:
    "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
  family: "Montserrat",
  src:
    "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf",
});
const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 10;
const COL2_WIDTH = 60;
const alternateStyles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: 0,
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    fontSize: 11,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    padding: 5,
  },
  tableCol2Header: {
    width: COL2_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,

    fontSize: 11,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    padding: 5,
  },
  tableColTableHeader: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    padding: 5,
  },

  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableCol2: {
    width: COL2_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },

  tableColTable: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
    padding: 5,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    padding: 5,
  },
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingHorizontal: 15,
  },
  title: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    textAlign: "center",
    fontFamily: "Montserrat",
    lineHeight: 1.5,
  },
  text: {
    fontSize: 13,
    margin: 20,
    fontFamily: "Montserrat",
    lineHeight: 1.8,
  },
  textRight: {
    fontSize: 13,
    margin: 15,
    fontFamily: "Montserrat",
    lineHeight: 1.5,
    textAlign: "right",
  },

  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

function CrecheList({ title, crechesForRoom }) {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  return (
    <Page size="A4" style={styles.body} wrap={true}>
      <Text style={styles.textRight}>
        {mm}/{dd}/{yyyy}
      </Text>
      <Text style={styles.title}>{title}</Text>

      <View style={alternateStyles.table}>
        <View style={alternateStyles.tableRow}>
          <View style={alternateStyles.tableCol1Header}></View>
          <View style={alternateStyles.tableColTableHeader}>
            <Text>ID</Text>
          </View>
          <View style={alternateStyles.tableCol2Header}>
            <Text>Description</Text>
          </View>
          <View style={alternateStyles.tableColTableHeader}>
            <Text>Room</Text>
          </View>
          <View style={alternateStyles.tableColTableHeader}>
            <Text>Table #</Text>
          </View>
        </View>
        {crechesForRoom.map((creche) => {
          return (
            <View style={alternateStyles.tableRow} wrap={false}>
              <View style={alternateStyles.tableCol1}>
                <Image
                  src={{
                    uri: `https://s3.us-west-2.amazonaws.com/admin.christmascreche.org/images/creches/${creche.image_path}`,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
              </View>
              <View style={alternateStyles.tableColTable}>
                <Text>{creche.creche_id}</Text>
              </View>
              <View style={alternateStyles.tableCol2}>
                <Text>{creche.description}</Text>
              </View>
              <View style={alternateStyles.tableColTable}>
                <Text>{creche.room}</Text>
              </View>
              <View style={alternateStyles.tableColTable}>
                <Text>{creche.room_table === 0 ? "" : creche.room_table}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </Page>
  );
}

function RoomAndTable({ creches }) {
  return (
    <Document>
      {creches.map((crecheList) => {
        return (
          <>
            <CrecheList
              title={`${crecheList[0].first_name} ${crecheList[0].last_name}`}
              crechesForRoom={crecheList}
            />
          </>
        );
      })}
    </Document>
  );
}

export default function ExhibitByRoomAndTablePDF() {
  let { id } = useParams();
  const [creches, setCreches] = useState([]);
  function exhibitSuccess(requestData) {
    setCreches(requestData);
  }
  return (
    <RequestWrapper
      requestType="get"
      requestEndpoint={`/exhibits/${id}/form-letter`}
      requestErrorCallback={() => {
        console.log("error");
      }}
      requestSuccessCallback={exhibitSuccess}
    >
      <PDFViewer style={{ flex: 1, height: 1000, width: "100%" }}>
        <RoomAndTable creches={creches} />
      </PDFViewer>
    </RequestWrapper>
  );
}
