import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import {
  Modal,
  RequestWrapperMain,
  ENDPOINT,
  CrecheImage,
} from "../HelperComponents";
import useCreche from "../hooks/useCreche";
import { binderCodeOptions, countryCodeOptions } from "./utils";
import useExhibitors from "../hooks/useExhibitors";
import ExhibitAddCrecheForm from "../Exhibits/ExhibitAddCrecheForm";

export default function CrecheEdit() {
  let { id } = useParams();
  const [showAddCrecheModal, setShowAddCrecheModal] = useState(false);
  const [creche, crecheRequestState, setCreche] = useCreche(id);
  const [exhibitors] = useExhibitors();
  const navigate = useNavigate();
  return (
    <>
      <RequestWrapperMain requestState={crecheRequestState}>
        <Formik
          enableReinitialize={true}
          initialValues={creche}
          onSubmit={async (values, { setSubmitting }) => {
            const { file } = values;
            if (file && file.name !== null) {
              values["image_path"] = file.name;
              values["image_type"] = file.type;
              delete values[file];
            }

            let results = {};

            if (id === "new") {
              results = await axios.post(`${ENDPOINT}/creches`, values);
            } else {
              results = await axios.put(`${ENDPOINT}/creches/${id}`, values);
            }

            if (results.data.success["presignedURL"] != null) {
              let options = {
                headers: {
                  "Content-Type": file.type,
                },
              };
              try {
                let s3Response = await axios.put(
                  results.data.success["presignedURL"],
                  file,
                  options
                );
                if (s3Response.status === 200) {
                  setCreche((prevState) => ({
                    ...prevState,
                    ["image_path"]: file.name,
                  }));
                }
              } catch (e) {}
            }

            if (id === "new") {
              navigate(`/creches/${results.data.success["insertId"]}`);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="is-flex mb-6 is-justify-content-space-between">
                <button
                  className={`button is-medium is-primary ${
                    isSubmitting ? "is-loading" : ""
                  }`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
                <button
                  className="button is-medium is-info ml-1"
                  onClick={() => {
                    setShowAddCrecheModal(true);
                  }}
                >
                  Add To Exhibit
                </button>
              </div>
              <div class="columns">
                <div class="column">
                  <div className="field" style={{ width: "100%" }}>
                    <label class="label">Exhibitor</label>
                    <div className="control">
                      <div style={{ width: "100%" }} className="select">
                        <Field
                          className="is-fullwidth"
                          as="select"
                          name="exhibitor_id"
                          style={{ width: "100%" }}
                        >
                          <option value=""></option>
                          {exhibitors.map((exhibitor) => (
                            <option value={exhibitor.exhibitor_id}>
                              {exhibitor.last_name}, {exhibitor.first_name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <label class="label">Material</label>
                      <Field class="input" type="text" name="material" />
                    </div>
                  </div>
                  <div className="field">
                    <div class="control">
                      <label class="label">Colors</label>
                      <Field class="input" type="text" name="colors" />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Description</label>
                    <div class="control">
                      <Field
                        class="textarea"
                        type="text"
                        as="textarea"
                        name="description"
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Card Text</label>
                    <div class="control">
                      <Field
                        class="textarea"
                        type="text"
                        as="textarea"
                        name="card_text"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Notes</label>
                    <div class="control">
                      <Field
                        class="textarea"
                        type="text"
                        as="textarea"
                        name="notes"
                      />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div className="mb-3 is-flex-direction-row is-flex is-justify-content-space-between">
                    <div className="field" style={{ width: "30%" }}>
                      <label class="label">Status</label>
                      <div className="control">
                        <div style={{ width: "100%" }} className="select">
                          <Field
                            style={{ width: "100%" }}
                            className="is-fullwidth"
                            as="select"
                            name="status"
                          >
                            <option value=""></option>
                            <option value="active">Available</option>
                            <option value="inactive">Unavailable</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="field" style={{ width: "30%" }}>
                      <label class="label">Country</label>
                      <div style={{ width: "100%" }} className="control">
                        <Field
                          style={{ width: "100%" }}
                          className="input"
                          id="country"
                          name="country"
                          placeholder="Country"
                          as="select"
                        >
                          <option value=""></option>
                          {countryCodeOptions.map((countryCode) => {
                            return (
                              <option value={countryCode.value}>
                                {countryCode.name}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                    </div>
                    <div className="field" style={{ width: "30%" }}>
                      <label class="label">Binder Code</label>
                      <div className="control">
                        <Field
                          className="input"
                          id="binder_code"
                          name="binder_code"
                          as="select"
                        >
                          <option value=""></option>
                          {binderCodeOptions.map((binderCode) => {
                            return (
                              <option value={binderCode.value}>
                                {binderCode.name}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 is-flex-direction-row is-flex is-justify-content-space-between">
                    <div className="field" style={{ width: "30%" }}>
                      <label class="label">Card Size</label>
                      <div className="control">
                        <div style={{ width: "100%" }} class="select">
                          <Field
                            style={{ width: "100%" }}
                            as="select"
                            name="card_size"
                          >
                            <option value="">Unknown</option>
                            <option value="tiny">Tiny</option>
                            <option value="small">Small</option>
                            <option value="medium">Medium</option>
                            <option value="large">Large</option>
                            <option value="jumbo">Jumbo</option>
                            <option value="super jumbo">Super Jumbo</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="field" style={{ width: "30%" }}>
                      <label class="label">Size</label>
                      <div class="control">
                        <Field class="input" type="text" name="size" />
                      </div>
                    </div>
                    <div className="field" style={{ width: "30%" }}>
                      <div class="control">
                        <label class="label">Pieces</label>
                        <Field class="input" type="text" name="pieces" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="label">Image</label>
                    <CrecheImage
                      creche_id={creche.creche_id}
                      image_path={creche.image_path}
                      ratio="is-4by3"
                    />

                    <div class="control">
                      <input
                        id="image_path"
                        name="file"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </RequestWrapperMain>

      <Modal
        title={`Add to Creche`}
        onClose={() => {
          setShowAddCrecheModal(false);
        }}
        content={
          <ExhibitAddCrecheForm
            crecheId={creche.creche_id}
            requestSuccessCallback={() => {
              setShowAddCrecheModal(false);
            }}
          />
        }
        isActive={showAddCrecheModal}
      />
    </>
  );
}
