import React from "react";
import { Link } from "react-router-dom";
import crechelogo from "../crechelogo.png";
import { useAuth0 } from "@auth0/auth0-react";
export default function NavBar() {
  const { isAuthenticated, isLoading } = useAuth0();
  return (
    <nav
      className="pt-3 pb-3 block navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <a href="/">
            <img
              alt="Creche Experience Logo"
              src={crechelogo}
              width="200"
              height="80"
              style={{ maxHeight: "inherit", filter: "invert(100%)" }}
            />
          </a>
        </div>
        <div className="navbar-menu">
          <div className="navbar-end">
            {!isLoading && isAuthenticated && (
              <>
                <Link className="navbar-item" to="/">
                  Creches
                </Link>
                <Link className="navbar-item" to="/exhibitors">
                  Exhibitors
                </Link>
                <Link className="navbar-item" to="/exhibits">
                  Exhibits
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
