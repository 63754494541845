import React, { useEffect, useState } from "react";
import missingimage from "./images/missingimage.png";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
export const LOADING_STATE = "loading";
export const SUCCESS_STATE = "success";
export const ERROR_STATE = "error";
export const ENDPOINT =
  process.env.NODE_ENV === "development"
    ? `http://localhost:3000/dev`
    : "https://8egye9pko8.execute-api.us-west-2.amazonaws.com/dev";

export function RequestWrapperMain({ requestState, children }) {
  switch (requestState) {
    case ERROR_STATE:
      return (
        <div className="box has-background-danger-dark has-text-danger-light is-size-4 has-text-centered">
          <FontAwesomeIcon icon={faExclamationTriangle} /> There was a problem
          with your request <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      );
    case LOADING_STATE:
      return (
        <div
          style={{ display: "flex" }}
          className="block mt-5 has-text-centered is-flex-direction-row is-justify-content-center is-align-content-center"
        >
          <div style={{ width: 75 }}>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </div>
        </div>
      );
    case SUCCESS_STATE:
    default:
      return <>{children}</>;
  }
}
export function RequestWrapper({
  requestType,
  requestEndpoint,
  requestSuccessCallback,
  requestErrorCallback,
  children,
}) {
  const [requestState, setRequestState] = useState(LOADING_STATE);
  useEffect(() => {
    async function fetchData() {
      await axios[requestType](`${ENDPOINT}${requestEndpoint}`)
        .then((response) => {
          setRequestState(SUCCESS_STATE);
          requestSuccessCallback(response.data.success);
        })
        .catch((error) => {
          setRequestState(ERROR_STATE);
          requestErrorCallback(error);
        });
    }
    fetchData();
  }, []);

  return (
    <RequestWrapperMain requestState={requestState}>
      {children}
    </RequestWrapperMain>
  );
}
export function PageTitle({ children }) {
  return (
    <div className="block">
      <h1 className="is-capitalized has-text-weight-light title is-2">
        {children}
      </h1>
      <hr />
    </div>
  );
}

export function PageSubTitle({ children }) {
  return (
    <>
      <h2
        className="has-text-black is-size-4 has-text-weight-light is-uppercase is-clearfix"
        style={{ letterSpacing: "0.3rem", marginTop: 15 }}
      >
        {children}
      </h2>
      <hr style={{ marginTop: ".5rem" }} />
    </>
  );
}
export function FormErrorMessage({ onClose }) {
  return (
    <div className="message is-danger">
      <div className="message-header">
        <p>Error</p>
        <button
          className="delete"
          aria-label="delete"
          onClick={onClose}
        ></button>
      </div>
      <div className="message-body">
        There was an issue submitting this form, please contact the site
        administrator.
      </div>
    </div>
  );
}

export function CrecheImage({ creche_id, image_path, ratio }) {
  return (
    <figure className={`image ${ratio}`}>
      <a href={`/creches/${creche_id}`}>
        {image_path !== null ? (
          <img
            src={`https://s3.us-west-2.amazonaws.com/admin.christmascreche.org/images/creches/${image_path}`}
            alt="Creche"
          />
        ) : (
          <img src={missingimage} alt="Missing Creche" />
        )}
      </a>
    </figure>
  );
}

export function Modal({ content, title, footer, isActive, onClose }) {
  let activeClassName = isActive ? "is-active" : "";

  return (
    <div className={`modal ${activeClassName}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="modal-card">
          <header className="modal-card-head is-primary">
            <p className="modal-card-title">{title}</p>
            <button
              className="delete"
              aria-label="close"
              onClick={onClose}
              type="button"
            ></button>
          </header>
          <section className="modal-card-body">{content}</section>
          <footer className="modal-card-foot">{footer}</footer>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    </div>
  );
}

export function getBinderCodeColor(binder_code) {
  const colorDictionary = {
    Africa: "#f8afaf",
    Asia: "#eed7d1",
    "Asia--Central": "#eed7d1",
    "Asia--East/Southeast": "#eed7d1",
    "Australia & Pacific Island": "#F3B562",
    Caribbean: "#8CBEB2",
    "Childrens & Whimsical": "#62f3b5",
    craft: "#62f3b5",
    Europe: "#c1b3b8",
    "Europe--Central": "#c1b3b8",
    "Europe--Eastern": "#c1b3b8",
    "Europe--Scandinavia": "#c1b3b8",
    "Europe--Southeast": "#c1b3b8",
    "Europe--West": "#c1b3b8",
    "Mexico/Central America": "#f48282",
    "Middle East": "#bff9f9",
    Miniatures: "#62a0f3",
    "Native America": "#b28cbe",
    NorAm: "#beb28c",
    "north  Amer--soft/misc": "#beb28c",
    "North Amer--Clay/glass": "#beb28c",
    "North Amer--hard": "#beb28c",
    "North Amer--Native America": "#beb28c",
    "north America": "#beb28c",
    "paintings and Wallhangings": "#ebbff2",
    "pictures/Wall Hangings": "#ebbff2",
    "pictures/Wallhangings": "#ebbff2",
    "Porcelain/Glass": "#ebbff2",
    "South America": "#bae3a4",
    test: "#ebbff2",
    Walls: "#ebbff2",
  };

  if (binder_code in colorDictionary) {
    return colorDictionary[binder_code];
  } else {
    return "#b8726d";
  }
}
