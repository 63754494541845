import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { RequestWrapper } from "../utils/RequestWrapper";
import { roomOptions } from "./utils";
import { ENDPOINT } from "../HelperComponents";

export default function ExhibitAddCrecheForm({
  crecheId,
  requestSuccessCallback,
}) {
  const [exhibits, setExhibits] = useState([]);
  const [currentExhibit, setCurrentExhibit] = useState("");

  function getExhibitsSuccess(data) {
    const currentExhibit = data.find((element) => {
      return element.is_current === 1;
    });
    setCurrentExhibit(currentExhibit.exhibit_id);
    setExhibits(data);
  }

  return (
    <RequestWrapper
      requestEndpoint={`/exhibits`}
      requestErrorCallback={() => {
        console.log("error");
      }}
      requestSuccessCallback={getExhibitsSuccess}
      requestType="get"
    >
      <Formik
        initialValues={{
          exhibit_id: currentExhibit,
          creche_id: "",
          room: "",
          table: "",
        }}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          values.creche_id = crecheId;
          await axios.post(
            `${ENDPOINT}/exhibits/${values.exhibit_id}/creches`,
            values
          );
          requestSuccessCallback();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="field">
              <label className="label" htmlFor="exhibit_id">
                Exhibit
              </label>
              <div className="control">
                <div className="select">
                  <Field
                    as="select"
                    name="exhibit_id"
                    style={{ width: "100%" }}
                  >
                    {exhibits.map((exhibit) => {
                      return (
                        <option
                          key={exhibit.exhibit_id}
                          value={exhibit.exhibit_id}
                        >
                          {exhibit.title}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>

              <ErrorMessage name="exhibit_id" />
            </div>
            <div className="field">
              <label className="label" htmlFor="exhibit_id">
                Room
              </label>
              <div className="control">
                <div className="select">
                  <Field as="select" name="room" style={{ width: "100%" }}>
                    <option key="empty_room" value=""></option>
                    {roomOptions.map((room) => {
                      return (
                        <option key={room.name} value={room.value}>
                          {room.name}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>

              <ErrorMessage name="room" />
            </div>
            <div className="field">
              <label className="label" htmlFor="table">
                Table
              </label>
              <div className="control">
                <div className="select">
                  <Field as="select" name="table" style={{ width: "100%" }}>
                    <option key="table_0" value=""></option>
                    <option key="table_1" value="1">
                      1
                    </option>
                    <option key="table_2" value="2">
                      2
                    </option>
                    <option key="table_3" value="3">
                      3
                    </option>
                    <option key="table_4" value="4">
                      4
                    </option>
                    <option key="table_5" value="5">
                      5
                    </option>
                    <option key="table_6" value="6">
                      6
                    </option>
                    <option key="table_7" value="7">
                      7
                    </option>
                    <option key="table_8" value="8">
                      8
                    </option>
                    <option key="table_9" value="9">
                      9
                    </option>
                    <option key="table_10" value="10">
                      10
                    </option>
                  </Field>
                </div>
              </div>

              <ErrorMessage name="table" />
            </div>
            <button
              className={`button is-link is-pulled-right ${isSubmitting &&
                "is-loading"}`}
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </RequestWrapper>
  );
}
