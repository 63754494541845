export const binderCodeOptions = [
  { name: "Unknown", value: "unknown" },
  { name: "Africa", value: "Africa" },
  { name: "Asia", value: "Asia" },
  { name: "Asia--Central", value: "Asia--Central" },
  { name: "Asia--East/Southeast", value: "Asia--East/Southeast" },
  { name: "Australia & Pacific Island", value: "Australia & Pacific Island" },
  { name: "Caribbean", value: "Caribbean" },
  { name: "Childrens & Whimsical", value: "Childrens & Whimsical" },
  { name: "craft", value: "craft" },
  { name: "Europe", value: "Europe" },
  { name: "Europe--Central", value: "Europe--Central" },
  { name: "Europe--Eastern", value: "Europe--Eastern" },
  { name: "Europe--Scandinavia", value: "Europe--Scandinavia" },
  { name: "Europe--Southeast", value: "Europe--Southeast" },
  { name: "Europe--West", value: "Europe--West" },
  { name: "Mexico/Central America", value: "Mexico/Central America" },
  { name: "Middle East", value: "Middle East" },
  { name: "Miniatures", value: "Miniatures" },
  { name: "Native America", value: "Native America" },
  { name: "North  Amer--soft/misc", value: "North  Amer--soft/misc" },
  { name: "North Amer--Clay/glass", value: "North Amer--Clay/glass" },
  { name: "North Amer--hard", value: "North Amer--hard" },
  { name: "North Amer--Native America", value: "North Amer--Native America" },
  { name: "pictures/Wallhangings", value: "pictures/Wallhangings" },
  { name: "Porcelain/Glass", value: "Porcelain/Glass" },
  { name: "South America", value: "South America" },
];

export const countryCodeOptions = [
  { name: "Africa", value: "Africa" },
  { name: "Argentina", value: "Argentina" },
  { name: "Aruba", value: "Aruba" },
  { name: "Asia - Sri Lanka", value: "Asia - Sri Lanka" },
  { name: "Austria", value: "Austria" },
  { name: "Azores", value: "Azores" },
  { name: "Bali", value: "Bali" },
  { name: "Bangladesh", value: "Bangladesh" },
  { name: "Bavaria", value: "Bavaria" },
  { name: "Bavaria, Germany", value: "Bavaria, Germany" },
  { name: "Belgium", value: "Belgium" },
  { name: "Belize", value: "Belize" },
  { name: "Bethlehem", value: "Bethlehem" },
  { name: "Bolivia", value: "Bolivia" },
  { name: "Brazil", value: "Brazil" },
  { name: "Bulgaria", value: "Bulgaria" },
  { name: "Burkina Faso", value: "Burkina Faso" },
  { name: "Cambodia", value: "Cambodia" },
  { name: "Cameroon", value: "Cameroon" },
  { name: "Canada", value: "Canada" },
  { name: "Caribbean Island", value: "Caribbean Island" },
  { name: "Central America", value: "Central America" },
  { name: "Childrens", value: "Childrens" },
  { name: "Chile", value: "Chile" },
  { name: "China", value: "China" },
  { name: "Colombia", value: "Colombia" },
  { name: "Conga", value: "Conga" },
  { name: "Costa Rica", value: "Costa Rica" },
  { name: "Croatia", value: "Croatia" },
  { name: "Czech Republic", value: "Czech Republic" },
  { name: "Czechoslovakia", value: "Czechoslovakia" },
  { name: "Denmark", value: "Denmark" },
  { name: "Dominican Republic", value: "Dominican Republic" },
  { name: "Ecuador", value: "Ecuador" },
  { name: "Egypt", value: "Egypt" },
  { name: "El Salvador", value: "El Salvador" },
  { name: "England", value: "England" },
  { name: "England/Wales", value: "England/Wales" },
  { name: "England?", value: "England?" },
  { name: "Ethiopia", value: "Ethiopia" },
  { name: "Europe", value: "Europe" },
  { name: "Finland", value: "Finland" },
  { name: "France", value: "France" },
  { name: "Georgia (was SSR)", value: "Georgia (was SSR)" },
  { name: "Germany", value: "Germany" },
  { name: "Ghana", value: "Ghana" },
  { name: "Greece", value: "Greece" },
  { name: "Guatamala", value: "Guatamala" },
  { name: "Guatemala", value: "Guatemala" },
  { name: "Haiti", value: "Haiti" },
  { name: "Hawaii", value: "Hawaii" },
  { name: "Hawaii USA", value: "Hawaii USA" },
  { name: "Holland", value: "Holland" },
  { name: "Honduras", value: "Honduras" },
  { name: "Hong Kong", value: "Hong Kong" },
  { name: "Hungary", value: "Hungary" },
  { name: "Iceland", value: "Iceland" },
  { name: "India", value: "India" },
  { name: "Indonesia", value: "Indonesia" },
  { name: "Ireland", value: "Ireland" },
  { name: "Israel", value: "Israel" },
  { name: "Israel?", value: "Israel?" },
  { name: "Italian", value: "Italian" },
  { name: "Italy", value: "Italy" },
  { name: "Italy or Switzerland", value: "Italy or Switzerland" },
  { name: "Italy?", value: "Italy?" },
  { name: "Ivory coast - Africa", value: "Ivory coast - Africa" },
  { name: "Jamaica", value: "Jamaica" },
  { name: "Japan", value: "Japan" },
  { name: "Jerusalem", value: "Jerusalem" },
  { name: "Jordan", value: "Jordan" },
  { name: "Kenya", value: "Kenya" },
  { name: "Kenya, Nairobi", value: "Kenya, Nairobi" },
  { name: "Kyrgyzstan", value: "Kyrgyzstan" },
  { name: "Laos", value: "Laos" },
  { name: "Latin America", value: "Latin America" },
  { name: "Lithuania", value: "Lithuania" },
  { name: "Luxemborg", value: "Luxemborg" },
  { name: "Madagascar", value: "Madagascar" },
  { name: "Malawi", value: "Malawi" },
  { name: "Mexico", value: "Mexico" },
  { name: "Mongolia", value: "Mongolia" },
  { name: "Montenegro", value: "Montenegro" },
  { name: "Myanmar", value: "Myanmar" },
  { name: "Native American", value: "Native American" },
  { name: "Nepal", value: "Nepal" },
  { name: "Netherlands", value: "Netherlands" },
  { name: "New Mexico", value: "New Mexico" },
  { name: "New Zealand", value: "New Zealand" },
  { name: "Nicaragua", value: "Nicaragua" },
  { name: "Nigeria", value: "Nigeria" },
  { name: "Nigeria or Ghana", value: "Nigeria or Ghana" },
  { name: "Northern Mexico", value: "Northern Mexico" },
  { name: "Norway", value: "Norway" },
  { name: "Nova Scotia", value: "Nova Scotia" },
  { name: "Pakistan", value: "Pakistan" },
  { name: "Palestine", value: "Palestine" },
  { name: "Panama", value: "Panama" },
  { name: "Paraguay", value: "Paraguay" },
  { name: "Peru", value: "Peru" },
  { name: "Philippines", value: "Philippines" },
  { name: "Phillippines", value: "Phillippines" },
  { name: "Poland", value: "Poland" },
  { name: "Polynesia", value: "Polynesia" },
  { name: "Portugal", value: "Portugal" },
  { name: "Puer o Rico", value: "Puer o Rico" },
  { name: "Puerto Rico", value: "Puerto Rico" },
  { name: "Rep. of Georgia", value: "Rep. of Georgia" },
  { name: "Romania", value: "Romania" },
  { name: "Russia", value: "Russia" },
  { name: "Rwanda", value: "Rwanda" },
  { name: "Scotland", value: "Scotland" },
  { name: "Senegal", value: "Senegal" },
  { name: "Singapore", value: "Singapore" },
  { name: "Slovak Republic", value: "Slovak Republic" },
  { name: "Slovakia", value: "Slovakia" },
  { name: "Solvenia", value: "Solvenia" },
  { name: "South Africa", value: "South Africa" },
  { name: "south America", value: "south America" },
  { name: "southwest", value: "southwest" },
  { name: "Spain", value: "Spain" },
  { name: "Spain?", value: "Spain?" },
  { name: "Sri Lanka", value: "Sri Lanka" },
  { name: "Sweden", value: "Sweden" },
  { name: "Switzerland", value: "Switzerland" },
  { name: "Taiwan", value: "Taiwan" },
  { name: "Tanzania", value: "Tanzania" },
  { name: "Thailand", value: "Thailand" },
  { name: "Thailand?", value: "Thailand?" },
  { name: "The Andes", value: "The Andes" },
  { name: "The Netherlands", value: "The Netherlands" },
  { name: "Turkey", value: "Turkey" },
  { name: "Uganda", value: "Uganda" },
  { name: "Ukraine", value: "Ukraine" },
  { name: "United States / Italy", value: "United States / Italy" },
  { name: "Unknown", value: "Unknown" },
  { name: "Uruguay", value: "Uruguay" },
  { name: "USA", value: "USA" },
  { name: "USA  China", value: "USA  China" },
  { name: "USA  Grailville, Ohio", value: "USA  Grailville, Ohio" },
  { name: "USA  Santa Fe", value: "USA  Santa Fe" },
  { name: "USA  Santa Fe, NM", value: "USA  Santa Fe, NM" },
  { name: "USA - Alaska", value: "USA - Alaska" },
  { name: "USA - Arizona", value: "USA - Arizona" },
  { name: "USA - Hawaii", value: "USA - Hawaii" },
  { name: "USA - Iowa", value: "USA - Iowa" },
  { name: "USA - Missouri", value: "USA - Missouri" },
  { name: "USA - New Mexico", value: "USA - New Mexico" },
  { name: "USA - New Orleans", value: "USA - New Orleans" },
  { name: "USA - Oklahoma", value: "USA - Oklahoma" },
  { name: "USA - Utah", value: "USA - Utah" },
  { name: "USA?", value: "USA?" },
  { name: "Uzbekistan", value: "Uzbekistan" },
  { name: "Various", value: "Various" },
  { name: "Vatican City", value: "Vatican City" },
  { name: "Venezuela", value: "Venezuela" },
  { name: "Vietnam", value: "Vietnam" },
  { name: "Wales", value: "Wales" },
  { name: "west Bank, Israel", value: "west Bank, Israel" },
  { name: "Zambia", value: "Zambia" },
  { name: "Zimbabwe", value: "Zimbabwe" },
];
