import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function ErrorState() {
  return (
    <div className="box has-background-danger-dark has-text-danger-light is-size-4 has-text-centered">
      <FontAwesomeIcon icon={faExclamationTriangle} /> There was a problem with
      your request <FontAwesomeIcon icon={faExclamationTriangle} />
    </div>
  );
}
