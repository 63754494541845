import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { FormErrorMessage, ENDPOINT } from "../HelperComponents";

export default function ExhibitorEditForm({ exhibitor }) {
  const { exhibitor_id } = exhibitor;
  const [showErrorModal, setShowErrorModal] = useState(false);
  return (
    <>
      {showErrorModal && (
        <FormErrorMessage
          onClose={() => {
            showErrorModal(false);
          }}
        />
      )}
      <Formik
        enableReinitialize={true}
        initialValues={exhibitor}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            let results = await axios.put(
              `${ENDPOINT}/exhibitors/${exhibitor_id}`,
              values
            );
            if (!results.status === 200) {
              setShowErrorModal(true);
            }
          } catch (e) {
            setShowErrorModal(true);
            console.log(e);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="first_name">
                    First Name
                  </label>
                  <div className="control">
                    <Field className="input" name="first_name" type="text" />
                    <ErrorMessage name="first_name" />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor="street_address">
                    Street Address
                  </label>
                  <div className="control">
                    <Field
                      className="input"
                      name="street_address"
                      type="text"
                    />
                    <ErrorMessage name="street_address" />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor="city">
                    City
                  </label>
                  <div className="control">
                    <Field className="input" name="city" type="text" />
                    <ErrorMessage name="city" />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="last_name">
                    Last Name
                  </label>
                  <div className="control">
                    <Field className="input" name="last_name" type="text" />
                    <ErrorMessage name="last_name" />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="phone_number">
                    Phone Number
                  </label>
                  <div className="control">
                    <Field className="input" name="phone_number" type="text" />
                    <ErrorMessage name="phone_number" />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="email">
                    Email
                  </label>
                  <div className="control">
                    <Field className="input" name="email" type="text" />
                    <ErrorMessage name="email" />
                  </div>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor="notes">
                Notes
              </label>
              <div className="control">
                <Field className="input" name="notes" type="text" />
                <ErrorMessage name="notes" />
              </div>
            </div>
            <div className="field">
              <label class="label">Status</label>
              <div className="control">
                <div style={{ width: "100%" }} className="select">
                  <Field
                    style={{ width: "100%" }}
                    className="is-fullwidth"
                    as="select"
                    name="card_size"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Field>
                </div>
              </div>
            </div>
            <button
              className={`button is-link ${isSubmitting ? "is-loading" : ""}`}
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}
