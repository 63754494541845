import React, { useState } from "react";
import { getBinderCodeColor } from "../HelperComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import missingimage from "../images/missingimage.png";
import axios from "axios";
import { ENDPOINT } from "../HelperComponents";

export function CrecheListItem(props) {
  const { creche, exhibitId, removeCreche } = props;
  const {
    creche_id,
    binder_code,
    description,
    material,
    country,
    image_path,
  } = creche;
  const binderCodeColor = getBinderCodeColor(binder_code);
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <>
      <div className="card m-4 bm--card-equal-height">
        <header className="card-header has-background-primary">
          <p className="is-justify-content-space-between card-header-title  has-text-weight-normal has-text-white">
            {creche_id}
            <span className="tag" style={{ backgroundColor: binderCodeColor }}>
              {binder_code}
            </span>
          </p>
        </header>
        <div className="card-image">
          <figure className="image is-4by3">
            {image_path !== null ? (
              <img
                src={`https://s3.us-west-2.amazonaws.com/admin.christmascreche.org/images/creches/${image_path}`}
                alt="Creche"
              />
            ) : (
              <img src={missingimage} alt="Missing Creche" />
            )}
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <span className="has-text-weight-bold">Description: </span>
            <span>{description}</span>
          </div>
          <div className="content">
            <span className="has-text-weight-bold">Material: </span>
            <span>{material}</span>
          </div>
          <div className="content">
            <span className="has-text-weight-bold">Country: </span>
            <span>{country}</span>
          </div>
        </div>
        <footer className="card-footer">
          <a
            style={{ borderRadius: 0, borderRight: 0 }}
            href={"/creches/" + creche_id}
            className="button is-primary has-text-white card-footer-item"
          >
            <FontAwesomeIcon icon={faEdit} />
          </a>
          <button
            style={{ fontSize: "1em", borderRadius: 0 }}
            onClick={async () => {
              setIsDeleting(true);
              let results = await axios.delete(
                `${ENDPOINT}/exhibits/${exhibitId}/creches/${creche_id}`
              );
              if (results.status === 200) {
                removeCreche(creche_id);
              }
              setIsDeleting(false);
            }}
            className={`button card-footer-item  ${
              isDeleting ? "is-loading" : ""
            }`}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </footer>
      </div>
    </>
  );
}
export default function Creches({ creches, exhibitId, removeCreche }) {
  return (
    <div className="columns is-multiline mb-6">
      {creches.map((creche) => {
        return (
          <div key={creche.description} className="column is-one-quarter">
            <CrecheListItem
              creche={creche}
              exhibitId={exhibitId}
              removeCreche={removeCreche}
            />
          </div>
        );
      })}
    </div>
  );
}
