import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, Form } from "formik";
import { useSearchParams } from "react-router-dom";
import useCreches from "../hooks/useCreches";
import {
  Modal,
  getBinderCodeColor,
  RequestWrapperMain,
  CrecheImage,
} from "../HelperComponents";
import trimText from "../utils/trimText";
import ExhibitAddCrecheForm from "../Exhibits/ExhibitAddCrecheForm";
import useExhibitors from "../hooks/useExhibitors";
import { binderCodeOptions, countryCodeOptions } from "./utils";

const PAGE_SIZE = 150;
const CrecheItemContext = React.createContext();

export function FilterCrecheForm({
  crecheFilterParams,
  setCrecheFilterParams,
}) {
  const [exhibitors] = useExhibitors();

  return (
    <Formik
      initialValues={{
        creche_id: crecheFilterParams.get("creche_id") || "",
        exhibitor: crecheFilterParams.get("exhibitor") || "",
        binder_code: crecheFilterParams.get("binder_code") || "",
        material: crecheFilterParams.get("material") || "",
        color: crecheFilterParams.get("color") || "",
        country: crecheFilterParams.get("country") || "",
      }}
      onSubmit={async (values) => {
        Object.keys(values).forEach((key) => {
          if (values[key] == null || values[key] === "") {
            delete values[key];
          }
        });

        setCrecheFilterParams(values);
      }}
    >
      <Form className="columns">
        <div className="field column">
          <label class="label">Creche ID</label>
          <div className="control">
            <Field className="input" id="creche_id" name="creche_id" />
          </div>
        </div>
        <div className="field column">
          <label class="label">Exhibitor</label>
          <div className="control">
            <Field
              className="input"
              id="exhibitor"
              name="exhibitor"
              as="select"
            >
              <option value=""></option>
              {exhibitors.map((exhibitor) => {
                return (
                  <option value={exhibitor.exhibitor_id}>
                    {`${exhibitor.last_name}, ${exhibitor.first_name}`}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="field column">
          <label class="label">Binder Code</label>
          <div className="control">
            <Field
              className="input"
              id="binder_code"
              name="binder_code"
              as="select"
            >
              <option value=""></option>
              {binderCodeOptions.map((binderCode) => {
                return (
                  <option value={binderCode.value}>{binderCode.name}</option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="field column">
          <label class="label">Country</label>
          <div className="control">
            <Field className="input" id="country" name="country" as="select">
              <option value=""></option>
              {countryCodeOptions.map((countryCode) => {
                return (
                  <option value={countryCode.value}>{countryCode.name}</option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="field column">
          <label class="label">Material</label>
          <div className="control">
            <Field className="input" id="material" name="material" />
          </div>
        </div>
        <div className="field column">
          <label class="label">Color</label>
          <div className="control">
            <Field className="input" id="color" name="color" />
          </div>
        </div>
        <div className="field column" style={{ marginTop: 30 }}>
          <div className="control ">
            <button className="button is-primary" type="submit">
              Filter
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export function BinderCode() {
  const { binder_code } = useContext(CrecheItemContext);
  const binderCodeColor = getBinderCodeColor(binder_code);
  return (
    <span className="tag" style={{ backgroundColor: binderCodeColor }}>
      {binder_code}
    </span>
  );
}

export function CardText() {
  const { card_text } = useContext(CrecheItemContext);
  const [primaryText, secondaryText] = trimText(card_text);
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore((showMore) => !showMore);
  };

  return (
    <div className="content">
      <span className="has-text-weight-bold">Card Text: </span>
      <span>{primaryText}</span>{" "}
      {secondaryText !== "" && (
        <>
          {showMore && <span>{secondaryText}</span>}
          <button
            onClick={toggleShowMore}
            style={{ display: "block", marginTop: 10 }}
            className="button is-small is-info"
          >
            {showMore ? "Hide" : "Read More..."}
          </button>
        </>
      )}
    </div>
  );
}

export function CardDescription() {
  const { description } = useContext(CrecheItemContext);
  return (
    <div className="content">
      <span className="has-text-weight-bold">Description: </span>
      <span>{description}</span>
    </div>
  );
}

export function CardCountry() {
  const { country } = useContext(CrecheItemContext);
  return (
    <div className="content">
      <span className="has-text-weight-bold">Country: </span>
      <span>{country}</span>
    </div>
  );
}

export function CardMaterial() {
  const { material } = useContext(CrecheItemContext);
  return (
    <div className="content">
      <span className="has-text-weight-bold">Material: </span>
      <span>{material}</span>
    </div>
  );
}

export function CardExhibitor() {
  const { exhibitor_for_card } = useContext(CrecheItemContext);
  return (
    <div className="content">
      <span className="has-text-weight-bold">Exhibitor: </span>
      <span>{exhibitor_for_card}</span>
    </div>
  );
}
export function CardSize() {
  const { size } = useContext(CrecheItemContext);
  return (
    <div className="content">
      <span className="has-text-weight-bold">Size: </span>
      <span>{size}</span>
    </div>
  );
}

export function CardColors() {
  const { colors } = useContext(CrecheItemContext);
  return (
    <div className="content">
      <span className="has-text-weight-bold">Colors: </span>
      <span>{colors}</span>
    </div>
  );
}

export function CardImage() {
  const { creche_id, image_path } = useContext(CrecheItemContext);
  return (
    <div className="card-image">
      <CrecheImage
        creche_id={creche_id}
        image_path={image_path}
        ratio="is-4by3"
      />
    </div>
  );
}

export function CrecheListItem(props) {
  const { addCreche, creche } = props;
  const { is_current, creche_id } = creche;
  const icon = is_current === 1 ? faCheckCircle : faPlus;
  const addedClass = is_current === 1 ? "is-info" : "is-link";
  return (
    <CrecheItemContext.Provider value={creche}>
      <div className="card m-4 bm--card-equal-height">
        <header className="card-header has-background-primary">
          <p className="is-justify-content-space-between card-header-title  has-text-weight-normal has-text-white">
            <a style={{ color: "white" }} href={`/creches/${creche_id}`}>
              {creche_id}
            </a>{" "}
            <BinderCode />
          </p>
        </header>
        <CardImage />
        <div className="card-content">
          <CardCountry />
          <CardExhibitor />
          <CardMaterial />
          <CardSize />
          <CardColors />
          <CardDescription />
        </div>
        <footer className="card-footer">
          <a
            style={{ borderRadius: 0, borderRight: 0 }}
            href={"/creches/" + creche_id}
            className="button is-primary has-text-white card-footer-item"
          >
            <FontAwesomeIcon icon={faEdit} />
          </a>
          <button
            style={{ fontSize: "1em", borderRadius: 0 }}
            onClick={() => {
              addCreche(creche_id);
            }}
            className={`button card-footer-item ${addedClass}`}
            disabled={is_current === 1}
          >
            <FontAwesomeIcon icon={icon} />
          </button>
        </footer>
      </div>
    </CrecheItemContext.Provider>
  );
}
export default function CrecheList(props) {
  let [searchParams] = useSearchParams();
  const [
    creches,
    totalCrecheCount,
    toggleCrecheAddedToCurrentExhibit,
    crechesRequestState,
    crecheFilterParams,
    setCrecheFilterParams,
  ] = useCreches(searchParams);
  const [showAddCrecheModal, setShowAddCrecheModal] = useState(false);
  const [currentSelectedCrecheId, setCurrentSelectedCrecheId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const paginationButtons = getPaginationButtons(totalCrecheCount);

  function updateOffset(pageNumber) {
    crecheFilterParams.set("offset", (pageNumber - 1) * PAGE_SIZE);
    setCrecheFilterParams(crecheFilterParams);
    setCurrentPage(pageNumber);
  }

  function getPaginationButtons(crecheCount) {
    const totalPages = Math.ceil(crecheCount / PAGE_SIZE);
    let paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          onClick={() => {
            updateOffset(i);
          }}
          className={`pagination-link ${currentPage === i ? "is-current" : ""}`}
          aria-label={`Goto page ${i}`}
          type="button"
        >
          {i}
        </button>
      );
    }
    return paginationButtons;
  }

  function addCreche(crecheId) {
    setShowAddCrecheModal(true);
    setCurrentSelectedCrecheId(crecheId);
  }

  return (
    <div>
      <div className="container has-background-light box p-4">
        <h2 className="is-capitalized has-text-weight-light title is-4">
          Filters
        </h2>
        <FilterCrecheForm
          crecheFilterParams={crecheFilterParams}
          setCrecheFilterParams={setCrecheFilterParams}
        />
      </div>
      <div className="container block">
        <RequestWrapperMain requestState={crechesRequestState}>
          <nav
            className="pagination my-5 "
            role="navigation"
            aria-label="pagination"
          >
            <ul className="pagination-list box is-justify-content-center">
              {paginationButtons.map((button) => {
                return <li>{button}</li>;
              })}
            </ul>
          </nav>
          <div className="columns is-multiline">
            {creches.map((creche) => {
              return (
                <div key={creche.description} className="column is-one-quarter">
                  <CrecheListItem creche={creche} addCreche={addCreche} />
                </div>
              );
            })}
          </div>
        </RequestWrapperMain>
      </div>

      <Modal
        title={`Add to Creche`}
        onClose={() => {
          setShowAddCrecheModal(false);
        }}
        content={
          <ExhibitAddCrecheForm
            crecheId={currentSelectedCrecheId}
            requestSuccessCallback={() => {
              toggleCrecheAddedToCurrentExhibit(currentSelectedCrecheId);
              setShowAddCrecheModal(false);
            }}
          />
        }
        isActive={showAddCrecheModal}
      />
    </div>
  );
}
