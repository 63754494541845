import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import RequiresAuth from "./utils/RequiresAuth";

import CrecheListPage from "./Creches/pages/CrecheListPage";
import CrecheEditPage from "./Creches/pages/CrecheEditPage";
import ExhibitListPage from "./Exhibits/pages/ExhibitListPage";
import ExhibitEditPage from "./Exhibits/pages/ExhibitEditPage";
import ExhibitorsListPage from "./Exhibitors/pages/ExhibitorsListPage";
import ExhibitorsEditPage from "./Exhibitors/pages/ExhibitorsEditPage";
import NavBar from "./Users/NavBar";
import UserProfile from "./Users/index";
import UserLogin from "./Users/Login";
import UserLogout from "./Users/Logout";
import LoadingSpinner from "./utils/LoadingSpinner";
import ErrorState from "./utils/ErrorState";
import ExhibitFormLetterPDF from "./PDFs/ExhibitFormLetterPDF";
import ExhibitTableListByRoomPDF from "./PDFs/ExhibitTableListByRoomPDF";
import ExhibitTableListByExhibitorPDF from "./PDFs/ExhibitTableListByExhibitorPDF";

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
function App() {
  const { isError, isLoading } = useAuth0();
  if (isError) {
    return <ErrorState />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Router>
      <div>
        <NavBar />
        <div className="container">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <RequiresAuth>
                  <CrecheListPage />
                </RequiresAuth>
              }
            />
            <Route
              path="/exhibitors"
              element={
                <RequiresAuth>
                  <ExhibitorsListPage />
                </RequiresAuth>
              }
            />
            <Route
              path="/exhibitors/:id"
              element={
                <RequiresAuth>
                  <ExhibitorsEditPage />
                </RequiresAuth>
              }
            />
            <Route
              path="/exhibits"
              element={
                <RequiresAuth>
                  <ExhibitListPage />
                </RequiresAuth>
              }
            />
            <Route
              path="/exhibits/:id"
              element={
                <RequiresAuth>
                  <ExhibitEditPage />
                </RequiresAuth>
              }
            />
            <Route
              path="/exhibits/:id/form-letter"
              element={<ExhibitFormLetterPDF />}
            />
            <Route
              path="/exhibits/:id/by-room"
              element={<ExhibitTableListByRoomPDF />}
            />
            <Route
              path="/exhibits/:id/by-exhibitor"
              element={<ExhibitTableListByExhibitorPDF />}
            />

            <Route
              path="/creches"
              element={
                <RequiresAuth>
                  <CrecheListPage />
                </RequiresAuth>
              }
            />
            <Route
              path="/creches/:id"
              element={
                <RequiresAuth>
                  <CrecheEditPage />
                </RequiresAuth>
              }
            />

            <Route path="/login" element={<UserLogin />} />
            <Route path="/logout" element={<UserLogout />} />
            <Route
              path="/profile"
              element={
                <RequiresAuth>
                  <UserProfile />
                </RequiresAuth>
              }
            />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
