import React from "react";
import { getBinderCodeColor } from "../HelperComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

export function CrecheListItem(props) {
  const { creche } = props;
  const { creche_id, binder_code, description, material, country } = creche;
  const binderCodeColor = getBinderCodeColor(binder_code);
  return (
    <>
      <div className="card m-4 bm--card-equal-height">
        <header className="card-header has-background-primary">
          <p className="is-justify-content-space-between card-header-title  has-text-weight-normal has-text-white">
            {creche_id}
            <span className="tag" style={{ backgroundColor: binderCodeColor }}>
              {binder_code}
            </span>
          </p>
        </header>

        <div className="card-content">
          <div className="content">
            <span className="has-text-weight-bold">Description: </span>
            <span>{description}</span>
          </div>
          <div className="content">
            <span className="has-text-weight-bold">Material: </span>
            <span>{material}</span>
          </div>
          <div className="content">
            <span className="has-text-weight-bold">Country: </span>
            <span>{country}</span>
          </div>
        </div>
        <footer className="card-footer">
          <a
            style={{ borderRadius: 0, borderRight: 0 }}
            href={"/creches/" + creche_id}
            className="button is-primary has-text-white card-footer-item"
          >
            <FontAwesomeIcon icon={faEdit} />
          </a>
          <button
            style={{ fontSize: "1em", borderRadius: 0 }}
            onClick={() => {
              console.log(creche_id);
            }}
            className={`button card-footer-item`}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </footer>
      </div>
    </>
  );
}
export default function Creches({ creches }) {
  return (
    <div className="columns is-multiline">
      {creches.map((creche) => {
        return (
          <div key={creche.description} className="column is-one-quarter">
            <CrecheListItem creche={creche} />
          </div>
        );
      })}
    </div>
  );
}
