import React from "react";
import ExhibitorsList from "../ExhibitorsList";
import { PageTitle } from "../../HelperComponents";

export default function ExhibitorsListPage(props) {
  return (
    <>
      <PageTitle>Exhibitors</PageTitle>
      <ExhibitorsList />
    </>
  );
}
