import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ExhibitEditForm from "../ExhibitEditForm";
import ExhibitCreches from "../ExhibitCreches";
import { PageTitle, RequestWrapper } from "../../HelperComponents";

export default function ExhibitEditPage(props) {
  let { id } = useParams();

  const [exhibit, setExhibit] = useState({
    description: "",
    title: "",
    year: "",
    is_current: 0,
  });
  const [creches, setCreches] = useState([]);

  function exhibitSuccess(requestData) {
    setExhibit(requestData.exhibit);
    setCreches(requestData.creches);
  }
  function removeCreche(crecheId) {
    setCreches((prevCreches) => {
      const newCreches = prevCreches.filter(
        (creche) => creche.creche_id !== crecheId
      );

      return newCreches;
    });
  }
  return (
    <RequestWrapper
      requestEndpoint={`/exhibits/${id}`}
      requestSuccessCallback={exhibitSuccess}
      requestType="get"
    >
      <PageTitle>Edit Exhibit</PageTitle>
      <ExhibitEditForm exhibit={exhibit} />
      <h3 className="is-capitalized has-text-weight-light title is-2 mt-6">
        Creches Assigned to this Exhibit
      </h3>
      <hr />
      <ExhibitCreches
        creches={creches}
        removeCreche={removeCreche}
        exhibitId={id}
      />
    </RequestWrapper>
  );
}
