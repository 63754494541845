import React from "react";
import CrecheEditForm from "../CrecheEditForm";
import { PageTitle } from "../../HelperComponents";

export default function CrecheListPage(props) {
  return (
    <>
      <PageTitle>Edit Creche</PageTitle>
      <CrecheEditForm />
    </>
  );
}
