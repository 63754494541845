import { useState, useEffect } from "react";
import axios from "axios";
import {
  LOADING_STATE,
  ERROR_STATE,
  SUCCESS_STATE,
  ENDPOINT,
} from "../HelperComponents";
import { useSearchParams, createSearchParams } from "react-router-dom";

export default function useCreches() {
  const [creches, setCreches] = useState([]);
  const [totalCrecheCount, setTotalCrecheCount] = useState(0);
  const [requestState, setRequestState] = useState(LOADING_STATE);
  const [crecheFilterParams, setCrecheFilterParams] = useSearchParams();

  function toggleCrecheAddedToCurrentExhibit(crecheId) {
    const indexOfCreche = creches.findIndex(
      (creche) => creche.creche_id === crecheId
    );
    setCreches((prevCreches) => {
      const { is_current } = prevCreches[indexOfCreche];
      prevCreches[indexOfCreche].is_current = is_current === 1 ? 0 : 1;
      return prevCreches;
    });
  }

  useEffect(() => {
    setRequestState(LOADING_STATE);
    async function fetchCreches() {
      try {
        let results = await axios.get(
          `${ENDPOINT}/creches?${createSearchParams(crecheFilterParams)}`
        );
        if (results.status === 200) {
          setTotalCrecheCount(results.data.success.shift().total_creches);
          setCreches(results.data.success);
          setRequestState(SUCCESS_STATE);
        } else {
          setRequestState(ERROR_STATE);
        }
      } catch (e) {
        setRequestState(ERROR_STATE);
      }
    }
    fetchCreches();
  }, [crecheFilterParams]);

  return [
    creches,
    totalCrecheCount,
    toggleCrecheAddedToCurrentExhibit,
    requestState,
    crecheFilterParams,
    setCrecheFilterParams,
  ];
}
