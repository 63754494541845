import { useState, useEffect } from "react";
import axios from "axios";
import {
  LOADING_STATE,
  ERROR_STATE,
  SUCCESS_STATE,
  ENDPOINT,
} from "../HelperComponents";

export default function useExhibits() {
  const [exhibits, setExhibits] = useState([]);
  const [requestState, setRequestState] = useState(LOADING_STATE);

  useEffect(() => {
    async function fetchExhibits() {
      try {
        let results = await axios.get(`${ENDPOINT}/exhibits`);
        if (results.status === 200) {
          setExhibits(results.data.success);
          setRequestState(SUCCESS_STATE);
        } else {
          setRequestState(ERROR_STATE);
        }
      } catch (e) {
        setRequestState(ERROR_STATE);
      }
    }
    fetchExhibits();
  }, []);

  return [exhibits, requestState, setExhibits, setRequestState];
}
