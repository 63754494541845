import React, { useState, useEffect } from "react";
import { ENDPOINT } from "../HelperComponents";

export default function Exhibitors(props) {
  const [exhibitors, setExhibitors] = useState([]);
  useEffect(() => {
    async function fetchExhibitors() {
      let results = await fetch(`${ENDPOINT}/exhibitors`).then((response) =>
        response.json()
      );
      setExhibitors(results.success);
    }
    fetchExhibitors();
  }, []);
  return (
    <>
      <div className="field has-addons" style={{ marginTop: 10, width: "40%" }}>
        <div className="control has-icons-left has-icons-right is-expanded">
          <input className="input" type="text" placeholder="Name" />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </div>
        <div className="control">
          <button type="button" className="button is-link">
            Search
          </button>
        </div>
      </div>

      <table className="table  is-fullwidth">
        <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {exhibitors.map((exhibitor) => {
            return (
              <tr key={exhibitor.exhibitor_id}>
                <td>{exhibitor.last_name}</td>
                <td>{exhibitor.first_name}</td>
                <td>{exhibitor.email}</td>
                <td>{exhibitor.phone_number}</td>
                <td>{exhibitor.notes}</td>
                <td>
                  <a
                    className="button is-primary"
                    href={`/exhibitors/${exhibitor.exhibitor_id}`}
                  >
                    Edit
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
