import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { RequestWrapper, CrecheImage } from "../HelperComponents";
import {
  Page,
  Text,
  Font,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  View,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans Light",
  src:
    "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf",
});

Font.register({
  family: "Open Sans",
  src:
    "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
  family: "Montserrat",
  src:
    "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf",
});
const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 10;
const COL2_WIDTH = 45;
const COLN_WIDTH = (100 - (COL1_WIDTH + COL2_WIDTH)) / 3;
const alternateStyles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",

    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableCol2Header: {
    width: COL2_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableCol2: {
    width: COL2_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 5,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
    padding: 5,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    padding: 5,
  },
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingHorizontal: 15,
  },
  exhibitorDetails: {
    fontSize: 13,
    fontFamily: "Montserrat",
  },
  title: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    textAlign: "center",
    fontFamily: "Montserrat",
    lineHeight: 1.5,
  },
  text: {
    fontSize: 13,
    margin: 20,
    fontFamily: "Montserrat",
    lineHeight: 1.8,
  },
  textRight: {
    fontSize: 13,
    margin: 15,
    fontFamily: "Montserrat",
    lineHeight: 1.5,
    textAlign: "right",
  },
  salutations: {
    fontSize: 13,
    marginBottom: 10,
    marginTop: 40,
    marginLeft: 20,
    fontFamily: "Montserrat",
    lineHeight: 1.5,
  },
  closing: {
    fontSize: 13,
    margin: 10,
    marginLeft: 20,
    fontFamily: "Montserrat",
    lineHeight: 1.8,
  },
  tableText: {
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 10,
    textAlign: "center",
  },
  tableTextDescription: {
    fontSize: 11,
    fontFamily: "Montserrat",
    padding: 10,
    textAlign: "center",
    width: "15%",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

function CrecheList({ exhibitorDetails, crechesForExhibitor }) {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  return (
    <Page size="A4" style={styles.body} wrap={true}>
      <Text style={styles.textRight}>
        {mm}/{dd}/{yyyy}
      </Text>
      <Text style={styles.exhibitorDetails}>
        {exhibitorDetails.first_name} {exhibitorDetails.last_name}
      </Text>
      <Text style={styles.exhibitorDetails}>
        {exhibitorDetails.street_address}{" "}
      </Text>
      <Text style={styles.exhibitorDetails}>{exhibitorDetails.city} </Text>
      <Text style={styles.exhibitorDetails}>{exhibitorDetails.email}</Text>
      <Text style={styles.exhibitorDetails}>{exhibitorDetails.phone} </Text>
      <Text style={styles.title}>Requested Nativities</Text>

      <View style={alternateStyles.table}>
        <View style={alternateStyles.tableRow}>
          <View style={alternateStyles.tableCol1Header}></View>
          <View style={alternateStyles.tableCol2Header}>
            <Text>Description</Text>
          </View>
          <View style={alternateStyles.tableColHeader}>
            <Text>Colors</Text>
          </View>
          <View style={alternateStyles.tableColHeader}>
            <Text>Size</Text>
          </View>
          <View style={alternateStyles.tableColHeader}>
            <Text>Accessories</Text>
          </View>
        </View>
        {crechesForExhibitor.map((creche) => {
          return (
            <View style={alternateStyles.tableRow} wrap={false}>
              <View style={alternateStyles.tableCol1}>
                <Image
                  src={{
                    uri: `https://s3.us-west-2.amazonaws.com/admin.christmascreche.org/images/creches/${creche.image_path}`,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
              </View>
              <View style={alternateStyles.tableCol2}>
                <Text>{creche.description}</Text>
              </View>
              <View style={alternateStyles.tableCol}>
                <Text>{creche.colors}</Text>
              </View>
              <View style={alternateStyles.tableCol}>
                <Text>{creche.size}</Text>
              </View>
              <View style={alternateStyles.tableCol}>
                <Text>{creche.accessories}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </Page>
  );
}

function FormLetter({ creches }) {
  return (
    <Document>
      {creches.map((crecheList) => {
        const exhibitorDetails = {
          first_name: crecheList[0].first_name,
          last_name: crecheList[0].last_name,
          street_address: crecheList[0].street_address,
          city: crecheList[0].city,
          email: crecheList[0].email,
          phone: crecheList[0].phone,
        };

        return (
          <>
            <CrecheList
              exhibitorDetails={exhibitorDetails}
              crechesForExhibitor={crecheList}
            />
          </>
        );
      })}
    </Document>
  );
}

export default function ExhibitFormLetterPDF() {
  let { id } = useParams();
  const [creches, setCreches] = useState([]);
  function exhibitSuccess(requestData) {
    setCreches(requestData);
  }
  return (
    <RequestWrapper
      requestType="get"
      requestEndpoint={`/exhibits/${id}/form-letter`}
      requestErrorCallback={() => {
        console.log("error");
      }}
      requestSuccessCallback={exhibitSuccess}
    >
      <PDFViewer style={{ flex: 1, height: 1000, width: "100%" }}>
        <FormLetter creches={creches} />
      </PDFViewer>
    </RequestWrapper>
  );
}
