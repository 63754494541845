import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ExhibitorsEditForm from "../ExhibitorsEditForm";
import { PageTitle, RequestWrapper } from "../../HelperComponents";
import ExhibitorCreches from "../ExhibitorCreches.js";
export default function ExhibitorsEditPage(props) {
  let { id } = useParams();

  const [exhibitor, setExhibitor] = useState({
    first_name: "",
    last_name: "",
    street_address: "",
    city: "",
    phone_number: "",
    email: "",
    status: "",
  });
  const [creches, setCreches] = useState([]);

  function exhibitSuccess(requestData) {
    console.log(requestData);
    setExhibitor(requestData.exhibitor);
    setCreches(requestData.creches);
  }
  return (
    <RequestWrapper
      requestEndpoint={`/exhibitors/${id}`}
      requestSuccessCallback={exhibitSuccess}
      requestType="get"
    >
      <PageTitle>Edit Exhibitor Information</PageTitle>
      <ExhibitorsEditForm exhibitor={exhibitor} />
      <h3 className="is-capitalized has-text-weight-light title is-2 mt-6">
        Creches belonging to: {exhibitor.first_name} {exhibitor.last_name}
      </h3>
      <hr />
      <ExhibitorCreches creches={creches} />
    </RequestWrapper>
  );
}
