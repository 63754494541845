import { useState, useEffect } from "react";
import axios from "axios";
import {
  LOADING_STATE,
  ERROR_STATE,
  SUCCESS_STATE,
  ENDPOINT,
} from "../HelperComponents";

export default function useCreche(crecheId) {
  const [creche, setCreche] = useState({
    description: "",
    size: "",
    color: "",
    pieces: "",
    material: "",
    card_size: "",
    card_text: "",
    country: "",
    notes: "",
    status: "active",
  });
  const [requestState, setRequestState] = useState(LOADING_STATE);

  useEffect(() => {
    async function fetchCreches() {
      try {
        let results = await axios.get(`${ENDPOINT}/creches/${crecheId}`);
        if (results.status === 200) {
          console.log(results.data);
          setCreche(results.data.success[0]);
          setRequestState(SUCCESS_STATE);
        } else {
          setRequestState(ERROR_STATE);
        }
      } catch (e) {
        setRequestState(ERROR_STATE);
      }
    }
    if (crecheId !== "new") {
      fetchCreches();
    } else {
      setRequestState(SUCCESS_STATE);
    }
  }, [crecheId]);
  return [creche, requestState, setCreche, setRequestState];
}
