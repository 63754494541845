import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { FormErrorMessage, ENDPOINT } from "../HelperComponents";

export default function ExhibitEditForm({ exhibit }) {
  const { exhibit_id } = exhibit;
  const [showErrorModal, setShowErrorModal] = useState(false);
  return (
    <>
      {showErrorModal && (
        <FormErrorMessage
          onClose={() => {
            showErrorModal(false);
          }}
        />
      )}
      <Formik
        enableReinitialize={true}
        initialValues={exhibit}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            let results = await axios.put(
              `${ENDPOINT}/exhibits/${exhibit_id}`,
              values
            );
            if (!results.status === 200) {
              setShowErrorModal(true);
            }
          } catch (e) {
            setShowErrorModal(true);
            console.log(e);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className="field">
              <label className="label" htmlFor="year">
                Year
              </label>
              <div className="control">
                <div className="select">
                  <Field as="select" name="year">
                    <option value="">Select</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Field>
                </div>
              </div>

              <ErrorMessage name="year" />
            </div>
            <div className="field">
              <label className="label" htmlFor="title">
                Title
              </label>
              <div className="control">
                <Field className="input" name="title" type="text" />
                <ErrorMessage name="title" />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="description">
                Description
              </label>
              <div className="control">
                <Field className="input" name="description" type="text" />
                <ErrorMessage name="description" />
              </div>
            </div>
            <div className="field">
              <label>
                <Field type="checkbox" name="is_current" /> Is Current Creche
              </label>
              <ErrorMessage name="is_current" />
            </div>

            <button
              className={`button is-link ${isSubmitting ? "is-loading" : ""}`}
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}
