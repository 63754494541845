import { useState, useEffect } from "react";
import { ENDPOINT } from "../HelperComponents";

export default function useExhibitors() {
  const [exhibitors, setExhibitors] = useState([]);
  const [totalExhibitorsCount, setTotalExhibitorsCount] = useState(0);

  function updateExhibitors(exhibitorId) {
    const indexOfCreche = exhibitors.findIndex(
      (exhibitor) => exhibitor.exhibitor === exhibitorId
    );
    setExhibitors((prevExhibitors) => {
      prevExhibitors[indexOfCreche].is_current = !prevExhibitors[indexOfCreche]
        .is_current;
      return prevExhibitors;
    });
  }

  useEffect(() => {
    async function fetchExhibitors() {
      let results = await fetch(`${ENDPOINT}/exhibitors`).then((response) =>
        response.json()
      );
      setTotalExhibitorsCount(results.success.shift());
      setExhibitors(results.success);
    }
    fetchExhibitors();
  }, []);

  return [exhibitors, totalExhibitorsCount, updateExhibitors];
}
