export const roomOptions = [
  { name: "Chapel", value: "Chapel" },
  { name: "Children's", value: "Children" },
  { name: "Cultural Hall", value: "Cultural Hall" },
  { name: "Exterior", value: "Exterior" },
  { name: "Front Foyer", value: "Front Foyer" },
  { name: "Gym", value: "Gym" },
  { name: "Nursery", value: "Nursery" },
  { name: "Rear Foyer", value: "Rear Foyer" },
  { name: "Relief Society", value: "Relief Society" },
  { name: "Stage", value: "Stage" },
  { name: "Young Women's", value: "Young Women" },
];
