import { useState, useEffect } from "react";
import axios from "axios";
import {
  LOADING_STATE,
  ERROR_STATE,
  SUCCESS_STATE,
  ENDPOINT,
} from "../HelperComponents";

export default function useCurrentExhibit() {
  const [currentExhibit, setCurrentExhibit] = useState({
    exhibit: { title: "", description: "" },
    creches: [],
  });
  const [requestState, setRequestState] = useState(LOADING_STATE);

  useEffect(() => {
    setRequestState(LOADING_STATE);
    async function fetchCurrentExhibit() {
      try {
        let results = await axios.get(`${ENDPOINT}/exhibits/current`);
        if (results.status === 200) {
          setCurrentExhibit(results.data.success);
          setRequestState(SUCCESS_STATE);
        } else {
          setRequestState(ERROR_STATE);
        }
      } catch (e) {
        setRequestState(ERROR_STATE);
      }
    }
    fetchCurrentExhibit();
  }, []);

  return [currentExhibit, requestState, setCurrentExhibit, setRequestState];
}
