import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

function RequiresAuth({ children }) {
  return children;
}

export default withAuthenticationRequired(RequiresAuth, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
