import React from "react";
import CrecheList from "../CrecheList";
import { PageTitle } from "../../HelperComponents";

export default function CrecheListPage(props) {
  return (
    <>
      <PageTitle>
        Creches{" "}
        <a
          style={{ marginTop: 5 }}
          className="button is-primary is-pulled-right"
          href="/creches/new"
        >
          Add Creche
        </a>
      </PageTitle>
      <CrecheList />
    </>
  );
}
