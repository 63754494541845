import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function LoadingSpinner() {
  return (
    <div
      style={{ display: "flex" }}
      className="block mt-5 has-text-centered is-flex-direction-row is-justify-content-center is-align-content-center"
    >
      <div style={{ width: 75 }}>
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      </div>
    </div>
  );
}
