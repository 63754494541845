import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faDownload,
  faMailBulk,
  faDoorClosed,
  faCalendarCheck,
  faHouseUser,
  faSpinner,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  CrecheImage,
  RequestWrapperMain,
  ENDPOINT,
} from "../../HelperComponents";
import useCurrentExhibit from "../../hooks/useCurrentExhibit";
import useExhibits from "../../hooks/useExhibits";
import { roomOptions } from "../utils";
const tables = [...new Array(31)].map((e, index) => index);
const CurrentExhibitCrecheRow = ({ creche, exhibitId, removeCreche }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingTable, setIsAddingTable] = useState(false);
  const [tableNumber, setTableNumber] = useState(creche.room_table);
  const loadingButton = isDeleting ? "is-loading" : "";
  const removeCrecheAsync = async (e) => {
    setIsDeleting(true);
    let results = await axios.delete(
      `${ENDPOINT}/exhibits/${exhibitId}/creches/${creche.creche_id}`
    );
    if (results.status === 200) {
      removeCreche(creche.creche_id);
    }
    setIsDeleting(false);
  };
  const updateTableAsync = async (e) => {
    let value = e.target.value;
    setIsAddingTable(true);
    try {
      let results = await axios.put(
        `${ENDPOINT}/exhibits/${exhibitId}/creches/${creche.creche_id}`,
        { table: value }
      );
      if (results.status === 200) {
        setTableNumber(value);
      }
      setIsAddingTable(false);
    } catch (e) {
      setIsAddingTable(false);
    }
  };
  return (
    <tr>
      <td style={{ width: "5%" }}>
        <a href={`/creches/${creche.creche_id}`} key={creche.creche_id}>
          {creche.creche_id}
        </a>
      </td>
      <td style={{ width: "10%" }}>
        <CrecheImage
          creche_id={creche.creche_id}
          image_path={creche.image_path}
        />
      </td>
      <td style={{ width: "30%" }}>
        <p>{creche.description}</p>
      </td>
      <td style={{ width: "15%" }}>
        {creche.first_name} {creche.last_name}
      </td>
      <td style={{ width: "15%" }}>{creche.phone_number}</td>
      <td style={{ width: "15%" }}>{creche.email}</td>
      <td style={{ width: "5%", alignContent: "center" }}>
        <button
          onClick={removeCrecheAsync}
          className={`button is-danger is-light is-small ${loadingButton}`}
        >
          Remove from Exhibit
        </button>
      </td>
      <td style={{ width: "50px", alignContent: "center" }}>
        {
          <div className="field">
            <p className="control has-icons-left">
              <span className="select">
                <select onChange={updateTableAsync} value={tableNumber}>
                  {tables.map((table) => {
                    if (table === 0) {
                      return (
                        <option key="table_0" value="0">
                          Add Table #
                        </option>
                      );
                    } else {
                      return (
                        <option key={`table_${table}`} value={table}>
                          {table}
                        </option>
                      );
                    }
                  })}
                </select>
              </span>
              <span className="icon is-small is-left">
                {isAddingTable ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : (
                  <FontAwesomeIcon icon={faHouseUser} />
                )}
              </span>
            </p>
          </div>
        }
      </td>
    </tr>
  );
};
const CurrentExhibitByExhibitors = ({
  exhibitors,
  exhibitId,
  removeCreche,
}) => {
  return (
    <>
      {" "}
      <div className="my-3">
        <a
          href={`/exhibits/${exhibitId}/by-exhibitor`}
          className="button is-info"
        >
          <FontAwesomeIcon icon={faHouseUser} style={{ marginRight: 5 }} />
          Generate Table List By Exhibitor
        </a>
      </div>
      <div>
        {exhibitors.map((creches) => {
          return (
            <table
              key={`exhibitor_${creches[0].exhibitor_id}`}
              className="table is-fullwidth is-striped is-bordered"
            >
              <thead>
                <tr>
                  <th
                    colSpan="8"
                    className="has-background-link-light is-size-6"
                  >
                    {`${creches[0].last_name}, ${creches[0].first_name}`}
                  </th>
                </tr>
              </thead>
              <tbody style={{ border: "1px solid " }}>
                {creches.length === 0 ? (
                  <tr>
                    <td colSpan="8">No Creches Assigned</td>
                  </tr>
                ) : (
                  creches.map((creche) => {
                    return (
                      <CurrentExhibitCrecheRow
                        key={creche.creche_id}
                        creche={creche}
                        exhibitId={exhibitId}
                        removeCreche={removeCreche}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          );
        })}
      </div>
    </>
  );
};
const CurrentExhibitRooms = ({ creches, exhibitId, removeCreche }) => {
  const rooms = { Unassigned: [] };
  roomOptions.push({ key: "Unassigned", value: "Unassigned" });
  roomOptions.forEach((room) => {
    rooms[room.value] = [];
  });

  creches.forEach((creche) => {
    const { room } = creche;
    const roomKey = room === null || room === "" ? "Unassigned" : room;
    rooms[roomKey].push(creche);
  });

  return (
    <>
      <div className="my-3">
        <a href={`/exhibits/${exhibitId}/by-room`} className="button is-info">
          <FontAwesomeIcon icon={faHouseUser} style={{ marginRight: 5 }} />
          Generate Table List By Room
        </a>
      </div>
      <div>
        {roomOptions.map(({ value }) => (
          <table
            key={`room_${value}`}
            className="table is-fullwidth is-striped is-bordered"
          >
            <thead>
              <tr>
                <th colSpan="8" className="has-background-link-light is-size-6">
                  {value}
                </th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid " }}>
              {rooms[value].length === 0 ? (
                <tr>
                  <td colSpan="8">No Creches Assigned</td>
                </tr>
              ) : (
                rooms[value].map((creche) => {
                  return (
                    <CurrentExhibitCrecheRow
                      key={`room_${creche.creche_id}`}
                      creche={creche}
                      exhibitId={exhibitId}
                      removeCreche={removeCreche}
                    />
                  );
                })
              )}
            </tbody>
          </table>
        ))}
      </div>
    </>
  );
};
const CurrentExhibitButtons = ({ exhibitors, exhibitId, crechesForCSV }) => {
  return (
    <>
      <section className="level is-mobile mt-4 mb-4">
        <div className="level-item has-text-centered">
          <a
            href={`/exhibits/${exhibitId}/form-letter`}
            className="button is-info"
          >
            <FontAwesomeIcon icon={faMailBulk} style={{ marginRight: 5 }} />
            Generate Exhibitor Request Letters
          </a>
        </div>
        <div className="level-item has-text-centered">
          <CSVLink
            data={crechesForCSV}
            filename={"crechesRSVP.csv"}
            className="button is-info"
            target="_blank"
          >
            <i className="fa fa-download" aria-hidden="true"></i>
            <FontAwesomeIcon
              icon={faCalendarCheck}
              style={{ marginRight: 5 }}
            />
            Download Exhibitor RSVP List
          </CSVLink>
        </div>
        <div className="level-item has-text-centered">
          <CSVLink
            data={exhibitors}
            filename={"exhibitorsAddresses.csv"}
            className="button is-info"
            target="_blank"
          >
            <i className="fa fa-download" aria-hidden="true"></i>
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
            Download Exhibitor Contact Information
          </CSVLink>
        </div>
      </section>
    </>
  );
};
const CurrentExhibitStats = ({ creches }) => {
  const exhibitors = {};
  const countries = {};
  creches.forEach((creche) => {
    const { exhibitor_id, country } = creche;
    if (!(exhibitor_id in exhibitors)) {
      exhibitors[exhibitor_id] = 1;
    }
    if (!(country in countries)) {
      countries[country] = 1;
    }
  });
  const exhibitorCount = Object.keys(exhibitors).length;
  const countryCount = Object.keys(countries).length;
  return (
    <>
      <section className="level is-mobile">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading"># of Creches</p>
            <p className="title">{creches.length}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading"># of Countries</p>
            <p className="title">{countryCount}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading"># of Exhibitors</p>
            <p className="title">{exhibitorCount}</p>
          </div>
        </div>
      </section>
    </>
  );
};

function CrecheComparator(a, b) {
  if (a.last_name < b.last_name) return -1;
  if (a.last_name > b.last_name) return 1;
  return 0;
}
export default function ExhibitList(props) {
  const [exhibits, exhibitsRequestState] = useExhibits();
  const [
    currentExhibit,
    currentExhibitRequestState,
    setCurrentExhibit,
  ] = useCurrentExhibit();
  const [selectedTab, setSelectedTab] = useState("room");
  const { creches, exhibit } = currentExhibit;

  const removeCreche = (creche_id) => {
    const newCreches = creches.filter((creche) => {
      return creche.creche_id !== creche_id;
    });
    setCurrentExhibit({ ...currentExhibit, creches: newCreches });
  };
  let exhibitors = [
    [
      "last_name",
      "first_name",
      "street_address",
      "city",
      "email",
      "phone_number",
    ],
  ];

  let crechesForCSV = [
    [
      "creche_id",
      "description",
      "room",
      "last_name",
      "first_name",
      "email",
      "phone_number",
      "notes",
    ],
  ];
  let exhibitorDictionary = {};
  let sortedCreches = creches.sort(CrecheComparator);
  let crechesGroupedByExhibitor = [];
  let currentExhibitorsCreches = [];
  sortedCreches.forEach((creche) => {
    const {
      creche_id,
      exhibitor_id,
      first_name,
      last_name,
      street_address,
      city,
      email,
      phone_number,
      description,
      room,
      notes,
    } = creche;
    crechesForCSV.push([
      creche_id,
      description,
      room,
      last_name,
      first_name,
      email,
      phone_number,
      notes,
    ]);

    if (!(exhibitor_id in exhibitorDictionary)) {
      exhibitors.push([
        last_name,
        first_name,
        street_address,
        city,
        email,
        phone_number,
      ]);
      crechesGroupedByExhibitor.push(currentExhibitorsCreches);
      currentExhibitorsCreches = [creche];
      exhibitorDictionary[exhibitor_id] = 1;
    } else {
      currentExhibitorsCreches.push(creche);
    }
  });

  crechesGroupedByExhibitor.shift();

  return (
    <>
      <RequestWrapperMain requestState={currentExhibitRequestState}>
        <div className="box p-4">
          <>
            <div className="mb-6">
              <h2 className="title is-1 has-text-centered is-spaced">
                {exhibit.title}
              </h2>
              <h6 className="subtitle is-4 has-text-centered is-italic">
                {exhibit.description}
              </h6>
            </div>
            <CurrentExhibitStats creches={creches} />

            <hr className="my-6" />
            <CurrentExhibitButtons
              exhibitId={exhibit.exhibit_id}
              exhibitors={exhibitors}
              crechesForCSV={crechesForCSV}
            />

            <hr className="my-6" />
            <div className="tabs is-toggle">
              <ul>
                <li className={selectedTab === "room" ? "is-active" : ""}>
                  <a
                    onClick={() => {
                      setSelectedTab("room");
                    }}
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faDoorClosed}
                        style={{ marginRight: 5 }}
                      />
                    </span>
                    <span>Nativities by Room</span>
                  </a>
                </li>
                <li className={selectedTab === "exhibitor" ? "is-active" : ""}>
                  <a
                    onClick={() => {
                      setSelectedTab("exhibitor");
                    }}
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: 5 }}
                      />
                    </span>
                    <span>Nativities by Exhibitor</span>
                  </a>
                </li>
              </ul>
            </div>

            {selectedTab === "room" ? (
              <CurrentExhibitRooms
                creches={creches}
                exhibitId={exhibit.exhibit_id}
                removeCreche={removeCreche}
              />
            ) : null}
            {selectedTab === "exhibitor" ? (
              <CurrentExhibitByExhibitors
                exhibitors={crechesGroupedByExhibitor}
                exhibitId={exhibit.exhibit_id}
                removeCreche={removeCreche}
              />
            ) : null}
          </>
        </div>
      </RequestWrapperMain>
      <div className="box">
        <RequestWrapperMain requestState={exhibitsRequestState}>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th className="has-text-centered" scope="col" border="bottom">
                  Title
                </th>
                <th className="has-text-centered" scope="col" border="bottom">
                  Year
                </th>
                <th className="has-text-centered" scope="col" border="bottom">
                  Description
                </th>
                <th
                  className="has-text-centered"
                  scope="col"
                  border="bottom"
                ></th>
              </tr>
            </thead>
            <tbody>
              {exhibits.map((exhibit) => {
                return (
                  <tr key={exhibit.description}>
                    <td align="center">{exhibit.title}</td>
                    <td align="center">{exhibit.year}</td>
                    <td align="center">{exhibit.description}</td>
                    <td align="center">
                      <a
                        href={`/exhibits/${exhibit.exhibit_id}`}
                        className="button is-primary"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </RequestWrapperMain>
      </div>
    </>
  );
}
