import React, { useEffect, useState } from "react";
import axios from "axios";

import ErrorState from "./ErrorState";
import LoadingSpinner from "./LoadingSpinner";

export const LOADING_STATE = "loading";
export const SUCCESS_STATE = "success";
export const ERROR_STATE = "error";

export const ENDPOINT =
  process.env.NODE_ENV === "development"
    ? `http://localhost:3000/dev`
    : "https://8egye9pko8.execute-api.us-west-2.amazonaws.com/dev";

export function RequestWrapper({
  requestType,
  requestEndpoint,
  requestSuccessCallback,
  requestErrorCallback,
  children,
}) {
  const [requestState, setRequestState] = useState(LOADING_STATE);
  useEffect(() => {
    async function fetchData() {
      await axios[requestType](`${ENDPOINT}${requestEndpoint}`)
        .then((response) => {
          setRequestState(SUCCESS_STATE);
          requestSuccessCallback(response.data.success);
        })
        .catch((error) => {
          setRequestState(ERROR_STATE);
          requestErrorCallback(error);
        });
    }
    fetchData();
  }, []);

  switch (requestState) {
    case ERROR_STATE:
      return <ErrorState />;
    case LOADING_STATE:
      return <LoadingSpinner />;
    case SUCCESS_STATE:
    default:
      return <>{children}</>;
  }
}
